import React, { useEffect, useState } from 'react'
import { Alert } from "react-bootstrap";
import { useLocation } from 'react-router-dom';

const FlashBox = () => {
    // const flash = JSON.parse(localStorage.getItem('flash'))
    const { state } = useLocation();
    const [show, setShow] = useState(true)

    const flash = state?.flash

    let msg = flash != null ? flash.msg : ""
    let type = flash != null ? flash.type : ""

    // On componentDidMount set the timer
    useEffect(() => {
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            setShow(false)
            // localStorage.removeItem('flash')
        }, 3000)

        return () => {
            clearTimeout(timeId)
        }
    }, []);

    // If show is false the component will return null and stop here
    if (!show) {
        return null;
    }

    return (
        msg && <Alert key={type} variant={type}>{msg}</Alert>
    )
}

export default FlashBox;