import React from 'react';
import {
    Route,
    Routes,
} from 'react-router-dom';

import UsersManage from "./Manage";
import View from "./View";

export default function UserRoutes() {
    return (
        <Routes>
            <Route path="/" element={<UsersManage />} />
            <Route path="/view/:id" element={<View />} />
        </Routes>
    );
}