import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalProduct from "../User/Modal";
import TopNavbar from "../User/TopNav";

export default function Dashboard() {
  const [showProductDetails, setShowProductDetails] = useState(false);

  const handleCloseProductDetails = () => setShowProductDetails(false);
  const handleShowProductDetails = () => setShowProductDetails(true);

  return (
    <>
      <div className="container">
        <TopNavbar />
        <main className="col-sm-12">
          <div className="overview-header">
            <h1 className="h2">Dashboard</h1>
          </div>

          <div className="overview-table">
            <h2 className="overview-header">Latest QR Created</h2>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Address</th>
                  <th scope="col">Contact</th>
                  <th scope="col">Product</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>rajnesh</th>
                  <td>mumbai</td>
                  <td>978965998</td>
                  <td>mobile</td>
                  <td className="tableaction">
                    <Link to="#" onClick={handleShowProductDetails}>
                      <i className="fa-regular fa-eye"></i>
                    </Link>
                    <Link>
                      <i className="fa-regular fa-trash-can"></i>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th>raj</th>
                  <td>delhi</td>
                  <td>787654458</td>
                  <td>Bag</td>
                  <td className="tableaction">
                    <Link onClick={handleShowProductDetails}>
                      <i className="fa-regular fa-eye"></i>
                    </Link>
                    <Link>
                      <i className="fa-regular fa-trash-can"></i>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th>rajan</th>
                  <td>lucknow</td>
                  <td>Thordfdnton</td>
                  <td>wallet</td>
                  <td className="tableaction">
                    <Link onClick={handleShowProductDetails}>
                      <i className="fa-regular fa-eye"></i>
                    </Link>
                    <Link>
                      <i className="fa-regular fa-trash-can"></i>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>

      <ModalProduct
        show={showProductDetails}
        handleClose={handleCloseProductDetails}
      />
    </>
  );
}
