import React, { useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { postApiWithoutToken } from '../../services/Api.service';

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const HandleResetPass = async (e) => {
    e.preventDefault();
    const validEmail = validateEmail(email);

    if (validEmail) {
      const res = await postApiWithoutToken({ email }, 'user/forgot-password');

      if (res.status == 200) {
        setValidationErrors({ success: "If you're registered you will recive an email. Please follow the steps!!!" });
      }
    } else {
      setValidationErrors({ err: 'Please enter a valid email.' });
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col sm="5">
            {validationErrors.err && (
              <div
                className="alert alert-danger"
                role="alert"
              >
                {validationErrors.err}
              </div>
            )}
            {validationErrors.success && (
              <div
                className="alert alert-success offer-success-message"
                role="alert"
              >
                {validationErrors.success}
              </div>
            )}
            <div className="loginpage">
              <h1>Forgot Password</h1>
              <div className="loginchild">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address<span className="error">*</span></Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={email || ""}
                      placeholder="Please enter a valid registered email.."
                      onChange={(e) => setEmail(e.target.value)}
                      required />
                    <Form.Text className="text-light">
                      Reset Link will be sent to registered email id
                    </Form.Text>
                  </Form.Group>

                  <button
                    type="submit"
                    onClick={HandleResetPass}
                    className="adminbtn"
                  >
                    Submit
                  </button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
