import React, { useRef, useState, useEffect } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { create } from './services/Api.service';
import FlashBox from '../src/components/Globals/FlashBox';

export default function Login() {
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [showPass, setShowPass] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem('token');

    if (token) {
      let userdetails = JSON.parse(localStorage.getItem('userdetails'));

      if (userdetails.user_type == "user") {
        navigate('/user/dashboard');
      } else if (userdetails.user_type == "admin") {
        navigate('/admin/dashboard');
      }
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleShowPassword = () => {
    setShowPass(!showPass)
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    const errors = validateFormFields(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      try {
        const res = await create(formData, 'user/login');

        if (res.status == 200) {
          let user = res.data.user;
          let packId = localStorage.getItem('packId');

          const userdata = (({ id, fname, lname, user_type }) => ({ id, fname, lname, user_type }))(user)
          localStorage.setItem('userdetails', JSON.stringify(userdata));
          localStorage.setItem('token', res.data.token);

          if (packId) {
            navigate("/checkout", { state: { packId: packId } })
            localStorage.removeItem('packId');
          } else if (user.user_type == "user") {

            navigate('/user/dashboard');
          } else if (user.user_type == "admin") {

            navigate('/admin/dashboard');
          }

        } else {
          setValidationErrors({ err: res.response.data.message });
        }
      } catch (error) {
        console.log(error);
        setValidationErrors({ err: 'An error occurred. Please try again later.' });
      }
    }
  };

  const validateFormFields = (formData) => {
    const errors = {};

    if (!formData.email || formData.email.trim() === '') {
      errors.email = 'Please enter an email address';
    } else if (!validateEmail(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (!formData.password || formData.password.trim() === '') {
      errors.password = 'Please enter a password';
    }

    return errors;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col sm="5">
            <FlashBox />
            <div className="loginpage">
              <h1>Login</h1>
              <div className="loginchild">
                {validationErrors.success && <span className='success'>{validationErrors.success}</span>}
                {validationErrors.err && <div className="login-error">{validationErrors.err}</div>}

                <form onSubmit={handleLogin} className="form-custom">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      value={formData.email || ''}
                      placeholder="Enter Email Id"
                      onChange={handleInputChange} />
                  </Form.Group>
                  {validationErrors.email && <div className="error login-error">{validationErrors.email}</div>}

                  <Form.Group className="mb-3" >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type={showPass ? "text" : "password"}
                      name="password"
                      value={formData.password || ''}
                      placeholder="Password"
                      onChange={handleInputChange} />
                    {validationErrors.password && <div className="error login-error">{validationErrors.password}</div>}
                    <span toggle="#password-field" className={`fa fa-fw ${showPass ? "fa-eye" : "fa-eye-slash"} field-icon toggle-password`} onClick={handleShowPassword}></span>
                  </Form.Group>

                  <Form.Group className="mb-3 forgotpass" >
                    <Link to="/users/forgot-password">Forgot Password</Link>
                  </Form.Group>
                  <Button type="submit" onClick={handleLogin} className="adminbtn">
                    Submit
                  </Button>
                </form>
                <div className="loginfooter">
                  <span>Or</span>
                  <Link to="/register">Create Account</Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
