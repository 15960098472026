import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { fetchApiWithoutToken } from "../../services/Api.service";

export default function Dashboard() {
  const params = useParams();
  const [validTag, setvalidTag] = useState(false);
  const [tagData, setTagData] = useState({});
  let tag = params.tag;

  useEffect(() => {
    if (tag) {
      fetchApiWithoutToken(`user/barcodes/scan?tag=${tag}`).then((res) => {
        if (res?.status == 200) {
          setTagData(res.data)
          setvalidTag(true)
        }
      });
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          {
            !validTag ? <div className="alert alert-danger" role="alert">Inavlid Tag</div> :
              <main className="col-sm-4 align-self-center">
                <div className="overview-header">
                  <h1 className="h2">TAG Details</h1>
                </div>

                <ListGroup>
                  <ListGroup.Item>
                    <b>Product Name:</b> {tagData.product_name}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Product Type:</b> {tagData.type.title}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Owner Name:</b> {tagData.user.fname}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Owner Email:</b> {tagData.user.email}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Owner State:</b> {tagData.user.state}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Owner City:</b> {tagData.user.city}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Owner Name:</b> {tagData.product_name}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Owner Name:</b> {tagData.product_name}
                  </ListGroup.Item>
                </ListGroup>
              </main>
          }
        </div>
      </div>
    </>
  );
}
