import React, { useState, useEffect, useRef } from "react";
import TopNavbar from "./fragments/TopNavbar";
import { Link, useNavigate } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import mainbannerimg from "../../img/mainbanner.png";
import howitsworks1 from "../../img/purchasetags.png";
import feature1 from "../../img/feature1.png";
import feature2 from "../../img/feature2.png";
import feature3 from "../../img/feature3.png";
import { fetchApiWithoutToken } from '../../services/Api.service';

export default function Homepage() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  let token = localStorage.getItem('token');

  useEffect(() => {
    fetchApiWithoutToken('user/barcode-packs').then((response) => {
      if (response && response.status == 200) {
        setData(response.data.data)
      }
    });
  }, []);

  function handlePurchase(item) {
    if (token) {
      navigate("/checkout", { state: { packId: btoa(item) } })
    } else {

      localStorage.setItem('packId', btoa(item));
      navigate("/register")
    }
  }

  return (
    <>
      <TopNavbar />
      <div className="mainbanner">
        <Container>
          <div className="leftcontent">
            <h1>
              Never lose your <br />
              belongings again
            </h1>
            <h2>Attach. Scan. Reunite.</h2>
            <Link>Get Your Tags Now</Link>
          </div>
          <div className="rightcontent">
            <img src={mainbannerimg} />
          </div>
        </Container>
      </div>
      <Container>
        <div className="aboutlf">
          <h3>About Lost and Found</h3>
          <p>
            Lost and Found is dedicated to helping you keep track of your
            personal belongings. We provide tags with unique barcodes that can
            be easily attached to items like mobile phones, wallets, keys, and
            other daily essentials. With our innovative tagging system, you can
            rest assured that if you misplace something, it can be returned to
            you quickly and securely.
          </p>
        </div>
        <div className="howitsworksmain">
          <div className="howitsworksjr">
            <div className="howitsworksjr1">
              <h3>How It Works</h3>
              <Row>
                <Col>
                  <Card>
                    <Card.Img variant="top" src={howitsworks1} />
                    <Card.Body>
                      <Card.Title>Purchase Tags</Card.Title>
                      <Card.Text>
                        Buy our durable, easy-to-use tags from our online store.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Img variant="top" src={howitsworks1} />
                    <Card.Body>
                      <Card.Title>Attach to Belongings</Card.Title>
                      <Card.Text>
                        Assign the tags to your items either manually or by
                        scanning the barcode.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Img variant="top" src={howitsworks1} />
                    <Card.Body>
                      <Card.Title>Get Found</Card.Title>
                      <Card.Text>
                        If someone finds your lost item, they can scan the tag
                        to contact you and arrange a return.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="featuresmain">
          <h3>Features</h3>
          <div className="featuresmainjr">
            <div className="featuresmainjr1">
              <Row>
                <Col>
                  <img src={feature1} />
                  <h4>Easy Tag Assignment</h4>
                  <span>
                    Assign tags to your items manually or by scanning.
                  </span>
                </Col>
                <Col>
                  <img src={feature2} />
                  <h4>Easy Tag Assignment</h4>
                  <span>
                    Assign tags to your items manually or by scanning.
                  </span>
                </Col>
                <Col>
                  <img src={feature3} />
                  <h4>Easy Tag Assignment</h4>
                  <span>
                    Assign tags to your items manually or by scanning.
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="pricing">
          <h3>Pricing</h3>
          <div className="pricingchild">
            <Row>
              {data.map((item, index) => (
                <Col key={index}>
                  <div className={`colorfullcard package${index}card`}>
                    <h4>{item.title}</h4>
                    <span>${item.price}</span>
                    <span>{item.count} Tags</span>
                    <button onClick={() => { handlePurchase(item.id) }}>Buy Now</button>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <div className="faq">
          <h3>Frequently asked questions</h3>
          <div className="faqchild">
            <div className="faqchild1">
              <h4>How do I assign a tag to my belongings?</h4>
              <p>
                You can assign a tag by scanning the barcode with our mobile app
                or entering the code manually on our website.
              </p>
            </div>
            <div className="faqchild1">
              <h4>What happens if someone finds my item?</h4>
              <p>
                You can assign a tag by scanning the barcode with our mobile app
                or entering the code manually on our website.
              </p>
            </div>
            <div className="faqchild1">
              <h4>How do I assign a tag to my belongings?</h4>
              <p>
                You can assign a tag by scanning the barcode with our mobile app
                or entering the code manually on our website.
              </p>
            </div>
            <div className="faqchild1">
              <h4>What happens if someone finds my item?</h4>
              <p>
                They can scan the tag and send you a message through our secure
                system to arrange the return.
              </p>
            </div>
            <div className="faqchild1">
              <h4>What happens if someone finds my item?</h4>
              <p>
                They can scan the tag and send you a message through our secure
                system to arrange the return.
              </p>
            </div>
            <div className="faqchild1">
              <h4>What happens if someone finds my item?"</h4>
              <p>
                They can scan the tag and send you a message through our secure
                system to arrange the return.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Container className="g-0" fluid>
        <div className="getintouch">
          <Row>
            <Col>
              <div className="contacttext">
                <h3>Get in Touch</h3>
                <span>
                  Have questions or need help? Our support team is here for you.
                </span>
              </div>
            </Col>
            <Col>
              <form>
                <h4>Contact Form:</h4>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                />
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                />
                <textarea
                  className="form-control"
                  placeholder="Message"
                  rows="3"
                ></textarea>
                <button type="submit">Send Message</button>
              </form>
            </Col>
          </Row>
        </div>
      </Container>
      <Container>
        <div className="footer">
          <div className="footermenu">
            <ul>
              <li>
                <Link>Home</Link>
              </li>
              <li>
                <Link>About</Link>
              </li>
              <li>
                <Link>How It Works</Link>
              </li>
              <li>
                <Link>Features</Link>
              </li>
              <li>
                <Link>Pricing</Link>
              </li>
              <li>
                <Link>FAQ</Link>
              </li>{" "}
              <li>
                <Link >Contact</Link>
              </li>
            </ul>
            <ul className="social-icons">
              <Link>
                <i className="fa-brands fa-facebook"></i>
              </Link>
              <Link>
                <i className="fa-brands fa-square-x-twitter"></i>
              </Link>
              <Link>
                <i className="fa-brands fa-instagram"></i>
              </Link>
            </ul>
          </div>
          <div className="copyright">
            <span>© 2003-2024 lostandfound.com, Inc.</span>
          </div>
        </div>
      </Container>
    </>
  );
}
