import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";

export default function TopNavbar() {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="bluenavbar" variant="dark">
        <Container>
          <Navbar.Brand to="/">Lost & Found</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="navitems">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/">
                About
              </Nav.Link>
              <Nav.Link>How it Works</Nav.Link>
              <Nav.Link>Features</Nav.Link>
              <Nav.Link>Features</Nav.Link>
              <Nav.Link>FAQ</Nav.Link>
              <Nav.Link>Contact Us</Nav.Link>
              <Nav.Link as={Link} to="/login">Login / Register</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
