import React from 'react';
import {
    Route,
    Routes,
} from 'react-router-dom';

import BarcodePacksManage from "./Manage";
import Edit from "./Edit";
import Add from "./Add";

export default function BarcodePacksRoutes() {
    return (
        <Routes>
            <Route path="/" element={<BarcodePacksManage />} />
            <Route path="/edit/:id" element={<Edit />} />
            <Route path="/add" element={<Add />} />
        </Routes>
    );
}