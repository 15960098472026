import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { create } from '../src/services/Api.service';
import FlashBox from '../src/components/Globals/FlashBox';


export default function Register() {
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [showPass, setShowPass] = useState(false)
  const [showConfPass, setConfShowPass] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      let userdetails = JSON.parse(localStorage.getItem('userdetails'));

      if (userdetails.user_type == "user") {
        navigate('/user/dashboard');
      } else if (userdetails.user_type == "admin") {
        navigate('/admin/dashboard');
      }
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const errors = validateFormFields(formData);

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      try {
        delete formData.confPassword;
        const res = await create(formData, 'user/register');

        if (res.status == 200) {
          let flash = JSON.stringify({ type: "success", msg: "Registered successfully. We've sent an email regarding further steps!!" })
          navigate("/login", { state: { flash } })
        } else {
          setValidationErrors({ err: res.response.data.message });
        }
      } catch (error) {
        console.log(error);
        setValidationErrors({ err: 'An error occurred. Please try again later.' });
      }
    }
  };

  const validateFormFields = (formData) => {
    const errors = {};

    if (!formData.fname || formData.fname.trim() === '') {
      errors.fname = 'Please enter first name...';
    }

    if (!formData.lname || formData.lname.trim() === '') {
      errors.lname = 'Please enter last name...';
    }

    if (!formData.email || formData.email.trim() === '') {
      errors.email = 'Please enter an email address';
    } else if (!validateEmail(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (!formData.contact || formData.contact.trim() === '') {
      errors.contact = 'Please enter contact number...';
    }

    if (!formData.password || formData.password.trim() === '') {
      errors.password = 'Please enter a password';
    }

    if (!formData.confPassword || formData.confPassword.trim() === '') {
      errors.confPassword = 'Please confirm your password';
    }

    if (formData.password !== formData.confPassword) {
      errors.password = 'Password and Confirm Passwords should be same';
    }

    return errors;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <FlashBox />
          <Col sm="5">
            <div className="loginpage registerpage">
              <h1>Register</h1>
              <div className="loginchild">
                {validationErrors.err && <div className="login-error">{validationErrors.err}</div>}
                <Form>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>First Name:<span className="error">*</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="fname"
                          value={formData.fname || ''}
                          placeholder="Please enter first name .."
                          onChange={handleInputChange}
                          required />
                      </Form.Group>
                      {validationErrors.fname && <div className="error login-error">{validationErrors.fname}</div>}
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Last Name:<span className="error">*</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="lname"
                          value={formData.lname || ''}
                          placeholder="Please enter last name .."
                          onChange={handleInputChange}
                          required />
                      </Form.Group>
                      {validationErrors.lname && <div className="error login-error">{validationErrors.lname}</div>}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Email:<span className="error">*</span></Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formData.email || ''}
                          placeholder="Please enter a valid email.."
                          onChange={handleInputChange}
                          required />
                      </Form.Group>
                      {validationErrors.email && <div className="error login-error">{validationErrors.email}</div>}
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Contact no:<span className="error">*</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="contact"
                          value={formData.contact || ''}
                          placeholder="Please enter contact number.."
                          onChange={handleInputChange}
                          pattern="\d*"
                          maxLength="10"
                          required />
                      </Form.Group>
                      {validationErrors.contact && <div className="error login-error">{validationErrors.contact}</div>}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Password:<span className="error">*</span></Form.Label>
                        <Form.Control
                          type={showPass ? "text" : "password"}
                          name="password"
                          value={formData.password || ''}
                          placeholder="Please enter password.."
                          onChange={handleInputChange}
                          required />
                      </Form.Group>
                      <span toggle="#password-field" className={`fa fa-fw ${showPass ? "fa-eye" : "fa-eye-slash"} field-icon toggle-password`} onClick={() => setShowPass(!showPass)}></span>
                      {validationErrors.password && <div className="error login-error">{validationErrors.password}</div>}
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Confirm Password:<span className="error">*</span></Form.Label>
                        <Form.Control
                          type={showConfPass ? "text" : "password"}
                          name="confPassword"
                          value={formData.confPassword || ''}
                          placeholder="Please confirm your password.."
                          onChange={handleInputChange}
                          required />
                      </Form.Group>
                      <span toggle="#password-field" className={`fa fa-fw ${showConfPass ? "fa-eye" : "fa-eye-slash"} field-icon toggle-password`} onClick={() => setConfShowPass(!showConfPass)}></span>
                      {validationErrors.confPassword && <div className="error login-error">{validationErrors.confPassword}</div>}
                    </Col>
                  </Row>

                  {/* <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Amount Payable:</Form.Label> &nbsp;&nbsp;&nbsp;
                        <Form.Label>${PackData.price}</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row> */}

                  <button type="submit" className="adminbtn" onClick={handleRegister}>
                    Submit
                  </button>
                </Form>
                <div className="loginfooter">
                  <span>Already have account ?</span>
                  <Link to="/login">Login</Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container >
    </>
  )

}
