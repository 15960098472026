import { Outlet } from "react-router";
import Nav from "./Includes/Nav";
import SideMenu from "./Includes/SideMenu";
import { Container, Row } from "react-bootstrap";

const DefaultContainer = () => {
    return (
        <>
        {/* <Container fluid>
            <Row> */}
            <Nav />
            <SideMenu />
            <Outlet />
            {/* </Row>
            </Container> */}
        </>
    )
}

export default DefaultContainer;