import React from "react";
import { Link } from "react-router-dom";

export default function Dashboard() {
  return (
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">

      <div class="overview-header">
        <h1 class="h2">Dashboard</h1>
        {/* <div class="buttons-right">
            <Link to="/addeditdeleteuser">
              <button class="btn btn-primary btn-add">
                {" "}
                <i class="fa-regular fa-square-plus"></i> Create User
              </button>
            </Link>
            <Link href="/app/importreport">
              <button class="btn btn-light">
                {" "}
                <i class="fa-solid fa-file-import"></i> Import User
              </button>
            </Link>
          </div> */}
      </div>
      <div class="overview-body">
        <div class="row">
          <div class="col-md-12">
            <div class="overview-tiles">
              <div class="overview-tile">
                <h4>Total User Created</h4>
                <div class="bigtext">
                  <span> 5 </span>
                  <Link>View All</Link>
                </div>
                <Link>
                  <span class="arrow-right">
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </Link>
              </div>
              <div class="overview-tile">
                <h4>Internal Teams </h4>
                <div class="bigtext">
                  <span> 19 </span>
                  <Link>View All</Link>
                </div>
                <Link>
                  <span class="arrow-right">
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </Link>
              </div>
              <div class="overview-tile">
                <h4>External team</h4>
                <div class="bigtext">
                  <span> 6 </span>
                  <Link>View All</Link>
                </div>
                <Link>
                  <span class="arrow-right">
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </Link>
              </div>
              <div class="overview-tile">
                <h4>Stake Holder</h4>
                <div class="bigtext">
                  <span> 0 </span>
                  <Link>View All</Link>
                </div>
                <Link>
                  <span class="arrow-right">
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="overview-table">
        <h2 className="overview-header">Latest User Created</h2>
        <table className="table table-striped">

          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Age</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>rajnesh</th>
              <td>Mark</td>
              <td>tto</td>
              <td>787654458</td>
            </tr>
            <tr>
              <th>raj</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>987654123</td>
            </tr>
            <tr>
              <th>rajan</th>
              <td>Dacfob</td>
              <td>Thordfdnton</td>
              <td>887654123</td>
            </tr>
          </tbody>

        </table>
      </div>

    </main>
  );
}
