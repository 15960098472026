import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

export default function Nav() {
  return (
    <>
      <nav className="navbar navbaruser">
        <Link
          className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6"
          to="/Dashboard"
        >
          Lost and Found
        </Link>
        <button
          className="navbar-toggler position-absolute d-md-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="navbar-nav">
          <div className="nav-item text-nowrap">
            <Dropdown>
              <Dropdown.Toggle
                variant="btn-outline-primary"
                id="dropdown-basic"
              >
                User Name
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link to="/editprofileuser">Profile</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="/">Sign out</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </nav>
    </>
  );
}
