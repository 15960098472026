var host = window.location.protocol + "//" + window.location.host;
const API_URL = host.includes("localhost") ? "http://localhost:4001/api" :`${host}/api`

function timeAgo(timestamp) {
  const now = new Date();
  const timeDifference = Math.floor((now - new Date(timestamp)) / 1000); // Difference in seconds

  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "week", seconds: 604800 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  for (let i = 0; i < intervals.length; i++) {
    const interval = Math.floor(timeDifference / intervals[i].seconds);
    if (interval >= 1) {
      return interval === 1
        ? `${interval} ${intervals[i].label} ago`
        : `${interval} ${intervals[i].label}s ago`;
    }
  }

  return "just now"; // In case the difference is less than 1 second
}

export { timeAgo, API_URL };
