import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { API_URL } from "./Helper.service";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp;
    const currentTime = Math.floor(Date.now() / 1000);

    if (expirationTime > currentTime) {
      config.headers["Authorization"] = `Basic ${token}`;
      return config;
    } else {
      window.location.href = "/";
    }
  } else {
    window.location.href = "/";
  }
});

const fetchApi = async (query) => {
  try {
    const res = await axiosInstance.get(query);
    return res;
  } catch (error) {
    console.error(error);
  }
};

const fetchApiWithoutToken = async (query) => {
    try {
        const res = await axios.get(`${API_URL}/${query}`);
        return res
    } catch (error) {
        console.error(error);
    }
}

const postApiWithoutToken = async (data, query) => {
  try {
      const res = await axios.post(`${API_URL}/${query}`, data);
      return res
  } catch (error) {
      console.error(error);
  }
}

const create = async (data, query) => {
    try {
        const res = query === 'user/login' || query === 'user/register' ? await axios.post(`${API_URL}/${query}`, data) : await axiosInstance.post(`/${query}`, data);
        return res;
    } catch (error) {
        return error
    }
};

const update = async (data, query) => {
  try {
    const res = await axiosInstance.put(`/${query}`, data);
    return res;
  } catch (error) {
    console.error(error);
  }
};

const remove = async (query) => {
  try {
    const res = await axiosInstance.delete(`/${query}`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export {
    fetchApi,
    fetchApiWithoutToken,
    postApiWithoutToken,
    create,
    update,
    remove
}
