import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button, Alert } from "react-bootstrap";

//--------------Import Services -----------------//
import { create, fetchApi, remove } from "../../../services/Api.service";
import { timeAgo } from "../../../services/Helper.service";

import DeleteConfirmation from "../../Globals/Confirm";

export default function CouponsManage() {
  const [data, setData] = useState([]);
  const [successMsg, setSuccessMsg] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  useEffect(() => {
    fetchApi("user/coupons").then((response) => {
      if (response.status == 200) {
        setData(response.data.data);
      }
    });
  }, []);

  const handleDelete = (id) => {
    remove(`admin/coupons/${id}`).then((response) => {
      if (response.status == 200) {
        fetchApi("user/coupons").then((response) => {
          if (response.status == 200) {
            setData(response.data.data);
          }
        });

        setDisplayConfirmationModal(false);
        setSuccessMsg("Deleted Successfully!!!");
      }
    });
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the displaying of the modal based on type and id
  const showDeleteModal = (id) => {
    setDeleteMessage(`Are you sure you want to delete?`);
    setDeleteId(id);
    setDisplayConfirmationModal(true);
  };

  return (
    <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div className="overview-header">
        {successMsg && <Alert variant="success">{successMsg}</Alert>}

        <h1 className="h2">Manage Coupons</h1>

        <div className="buttons-right">
          <Button
            className="me-2"
            variant="primary"
            onClick={() => navigate("/coupons/add")}
          >
            Add Coupon
          </Button>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Title</th>
            <th>Description</th>
            <th>Code</th>
            <th>Discount</th>
            <th>Active</th>
            <th>Created On</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <>
                <td>{++index}</td>
                <td>{item.title}</td>
                <td>{item.description}</td>
                <td>{item.code}</td>
                <td>{item.discount}%</td>
                <td>{item.active == 1 ? "Yes" : "No"}</td>
                <td>{timeAgo(item.created_on)}</td>
                <td className="delete-view-btn">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to={`/coupons/edit/${btoa(item.id)}`}
                  >
                    <i className="fa-regular fa-eye"></i>
                  </Link>
                  <Link
                    variant="danger"
                    onClick={() => showDeleteModal(item.id)}
                  >
                    <i className="fa-solid fa-trash-can"></i>
                  </Link>
                </td>
              </>
            </tr>
          ))}
        </tbody>
      </table>

      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleDelete}
        hideModal={hideConfirmationModal}
        deleteId={deleteId}
        message={deleteMessage}
      />
    </div>
  );
}
